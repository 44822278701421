:root {
  --background-color: #091c32;
  --card-color: #0d2743;
  --text-color: #0d2743;
  --text-color-hover: #b3d5ff;
  --box-shadow: -3px 3px 6px 0 rgb(0, 14, 30, 0.5);
}
body[data-theme="dark-theme"] {
  --background-color: #091c32;
  --card-color: #0d2743;
  --text-color: #c8e1ff;
  --text-color-hover: #b3d5ff;
  --box-shadow: -3px 3px 6px 0 rgb(0, 14, 30, 0.5);
}
body[data-theme="light-theme"] {
  --background-color: #f6f4eb;
  --card-color: #caedff;
  --text-color: #344555;
  --text-color-hover: #26323d;
  --box-shadow: -3px 3px 6px 0 #75c0f1;
}
html {
  height: 100%;
}
body {
  margin: 0;
  box-sizing: border-box;
  min-height: 100%;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  color: var(--text-color);
}
body,
h1,
h2,
h3,
h4,
p,
figure,
blockquote,
dl,
dd {
  margin: 0;
}
ul[role="list"],
ol[role="list"] {
  list-style: none;
}
html:focus-within {
  scroll-behavior: smooth;
}
body {
  text-rendering: optimizeSpeed;
  line-height: 1.5;
}
a:not([class]) {
  text-decoration-skip-ink: auto;
}
img,
picture {
  max-width: 100%;
  display: block;
}
input,
button,
textarea,
select {
  font: inherit;
}
.main-div {
  height: 100vh;
  display: flex;
  flex-direction: column;
}
.header {
  display: flex;
  justify-content: space-between;
  background-color: #ffffff;
  padding: 10px;
  align-items: center;
  border-radius: 5px;
  flex: 0 0 auto;
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.header div {
  width: 33.333%;
}
.header div p {
  font-weight: bold;
  text-decoration: underline;
  color: var(--background-color);
}
.header div h1 {
  color: var(--background-color);
}
.image-div {
  width: 150px;
}
.detail-div {
  background-color: #ffffff;
  margin-top: 10px;
  border-radius: 5px;
  flex: 1;
  margin-bottom: 10px;
  margin-left: 10px;
  margin-right: 10px;
}
.station-div {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 10px;
  padding: 10px;
}
.stations {
  border: var(--background-color) solid 1px;
  border-radius: 10px;
  padding: 10px;
  text-align: center;
}
.stations img {
  height: 200px;
  margin: auto;
}
.divider {
  height: 1px;
  width: 90%;
  background-color: #344555;
  margin: auto;
}
.detail-item {
  display: grid;
  grid-template-columns: 1fr 1px 1fr;
  margin: 5px 0;
}
.detail-item div {
  height: 30px;
}
.status_div {
  display: flex;
  align-items: center;
  gap: 10px;
  justify-content: space-between;
  margin: 5px 20px;
}
.power_status_div {
  display: flex;
  align-items: center;
  gap: 10px;
}
.power_status_button {
  width: 50px;
  aspect-ratio: 1;
  border-radius: 10000px;
  background-color: red;
}
.loading {
  display: flex;
  justify-content: center;
}
.extra-details-div {
  display: flex;
  gap: 10px;
  padding: 10px;
}
.idle {
  background-color: rgb(255, 244, 195);
  color: rgb(150, 122, 0);
  font-size: large;
  padding: 2px 10px;
  border: rgb(150, 122, 0) solid 1px;
  border-radius: 5px;
  font-weight: 700;
}
.error {
  background-color: rgb(255, 195, 195);
  color: rgb(150, 0, 0);
  font-size: large;
  padding: 2px 10px;
  border: rgb(150, 0, 0) solid 1px;
  border-radius: 5px;
  font-weight: 700;
}
.running {
  background-color: rgb(195, 255, 203);
  color: rgb(0, 150, 37);
  font-size: large;
  padding: 2px 10px;
  border: rgb(0, 150, 37) solid 1px;
  border-radius: 5px;
  font-weight: 700;
}
.tool_changer {
  background-color: rgb(195, 255, 203);
  color: rgb(0, 150, 37);
  font-size: large;
  padding: 2px 10px;
  border: rgb(0, 150, 37) solid 1px;
  border-radius: 5px;
  font-weight: 700;
}
.status {
  background-color: rgb(255, 244, 195);
  color: rgb(150, 122, 0);
  font-size: large;
  padding: 2px 10px;
  border: rgb(150, 122, 0) solid 1px;
  border-radius: 5px;
  font-weight: 700;
  margin: 10px;
}
@media (prefers-reduced-motion: reduce) {
  html:focus-within {
    scroll-behavior: auto;
  }
  *,
  *::before,
  *::after {
    animation-duration: 0.01ms !important ;
    animation-iteration-count: 1 !important ;
    transition-duration: 0.01ms !important ;
    scroll-behavior: auto !important ;
  }
}
@media screen and (max-width: 991px) {
  .station-div {
    grid-template-columns: repeat(2, 1fr);
  }
}
